import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import socket from '@lib/socket';
import {
  Center,
  Container,
  Loader,
  Paper,
  Space,
  Stack,
  Stepper,
  Text,
  Title,
} from '@mantine/core';
import { useCounter } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { IconCircleCheck } from '@tabler/icons-react';

import { CheckoutRoom } from '@interfaces/checkout.interface';

import { useGetHolidayForCheckoutQuery } from '@api/holidaysVariations.api';

import {
  decrementSessionRemainingTime,
  selectHoliday,
  setCosts,
  setExtras,
  setHoliday,
  setPartecipants,
  setRooms,
  setSessionRemainingTime,
} from '@slices/checkout.slice';

import Ensurances from '@components/checkout/Ensurances';
import Extras from '@components/checkout/Extras';
import Partecipants from '@components/checkout/Partecipants';
import Quotation from '@components/checkout/quotation/Quotation';
import Rooms from '@components/checkout/Rooms';
import Summary from '@components/checkout/Summary';

// TODO: handle all loading states and test with low bandwidth

export default function Checkout() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  // ==========================================================================
  // State
  // ==========================================================================
  const [active, { set, increment }] = useCounter(0);

  // const extras = useSelector(selectExtras);
  const selectedHoliday = useSelector(selectHoliday);

  useEffect(() => {
    // Start timing interval
    const interval = setInterval(() => {
      dispatch(decrementSessionRemainingTime());
    }, 1000);

    const onTimeUpdate = (time: number) => {
      dispatch(setSessionRemainingTime(time));
    };

    socket.on('checkout_session_time_update', onTimeUpdate);

    const onSessionExpired = () => {
      openConfirmModal({
        title: 'Tempo scaduto',
        children:
          'Il tuo tempo per effettuare la prenotazione è scaduto. Puoi effettuare una nuova prenotazione.',
        cancelProps: { display: 'none' },
        closeButtonProps: { display: 'none' },
        closeOnClickOutside: false,
        labels: { confirm: 'OK', cancel: '' },
        onConfirm: () => {
          dispatch(setPartecipants([]));
          dispatch(setRooms([]));
          dispatch(setExtras({}));
          dispatch(
            setCosts({
              holidayCost: 0,
              roomsCost: 0,
              extrasCost: 0,
              ensurancesCost: 0,
            }),
          );
          set(0);
        },
      });
    };

    socket.on('checkout_session_expired', onSessionExpired);

    return () => {
      clearInterval(interval);
      socket.off('checkout_session_time_update', onTimeUpdate);
      socket.off('checkout_session_expired', onSessionExpired);
    };
  }, [dispatch]);

  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: holiday,
    isLoading,
    error,
  } = useGetHolidayForCheckoutQuery({
    holidayId: searchParams.get('h') || '',
    variationId: searchParams.get('v') || '',
  });

  useEffect(() => {
    dispatch(setHoliday(holiday || null));
  }, [holiday]);

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onStepClick = (step: number) => {
    if (step === 0) {
      openConfirmModal({
        title: 'Annulla prenotazione',
        children:
          'Tornando al preventivo annullerai la prenotazione in corso e dovrai ricominciare da capo. Le camere selezionate potrebbero non essere più disponibili. Continuare?',
        labels: {
          cancel: 'Continua prenotazione',
          confirm: 'Annulla prenotazione',
        },
        confirmProps: { color: 'red' },
        onConfirm: () => {
          dispatch(setPartecipants([]));
          dispatch(setRooms([]));
          dispatch(setExtras({}));
          dispatch(
            setCosts({
              holidayCost: 0,
              roomsCost: 0,
              extrasCost: 0,
              ensurancesCost: 0,
            }),
          );
          set(step);

          socket.emit('checkout_session_finish');

          // TODO: refetch holiday
        },
      });
    } else {
      set(step);
    }
  };

  const onQuotationConfirm = (selectedRooms: CheckoutRoom[]) => {
    increment();

    dispatch(setSessionRemainingTime(900));

    socket.emit('checkout_session_start', {
      selectedRooms: selectedRooms.map((r) => ({
        id: r.room?.id,
        quantity: 1, // TODO: handle spot room types
      })),
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Container size="85rem" py="xl" h="100dvh">
      {isLoading ? (
        <Center h="100%">
          <Loader />
        </Center>
      ) : error || !selectedHoliday ? (
        <Center h="100%">
          <Text size="lg" fw="bold">
            La vacanza selezionata non è esistente o non è disponibile
          </Text>
        </Center>
      ) : (
        <Stack justify="space-between" h="100%">
          <Stack gap={0} flex={1}>
            <Title>{selectedHoliday.holiday.name}</Title>
            <Title order={3} c="dimmed">
              {new Intl.DateTimeFormat('it', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              }).format(new Date(selectedHoliday.startDate))}{' '}
              -{' '}
              {new Intl.DateTimeFormat('it', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              }).format(new Date(selectedHoliday.endDate))}
            </Title>

            <Stepper
              active={active}
              onStepClick={onStepClick}
              allowNextStepsSelect={false}
              mt="xl"
              flex={1}
              styles={{
                root: { display: 'flex', flexDirection: 'column' },
                content: { flexGrow: 1 },
              }}
            >
              <Stepper.Step label="Preventivo">
                <Paper withBorder p="xl" h="100%">
                  <Quotation onConfirm={onQuotationConfirm} />
                </Paper>
              </Stepper.Step>
              <Stepper.Step label="Partecipanti">
                <Paper withBorder p="xl" h="100%">
                  <Partecipants onConfirm={increment} />
                </Paper>
              </Stepper.Step>
              <Stepper.Step label="Camere">
                <Paper withBorder p="xl" h="100%">
                  <Rooms onConfirm={increment} />
                </Paper>
              </Stepper.Step>
              <Stepper.Step label="Extra">
                <Paper withBorder p="xl" h="100%">
                  <Extras onConfirm={increment} />
                </Paper>
              </Stepper.Step>
              <Stepper.Step label="Assicurazioni">
                <Paper withBorder p="xl" h="100%">
                  <Ensurances onConfirm={increment} />
                </Paper>
              </Stepper.Step>
              <Stepper.Step label="Riepilogo e pagamento">
                <Paper withBorder p="xl" h="100%">
                  <Summary onConfirm={increment} />
                </Paper>
              </Stepper.Step>
              <Stepper.Completed>
                <Stack mt="10rem" align="center">
                  <IconCircleCheck size={100} color="green" />
                  <Text size="xl" fw="bold">
                    Prenotazione completata!
                  </Text>
                  <Text>
                    Riceverai una email di conferma. Se non la trovi controlla
                    la cartella spam.
                  </Text>
                </Stack>
              </Stepper.Completed>
            </Stepper>
          </Stack>

          <Space h="xl" />
        </Stack>
      )}
    </Container>
  );
}
