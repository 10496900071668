import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  Checkbox,
  Divider,
  Grid,
  NumberInput,
  PasswordInput,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { handleSubmitError } from '@utils/forms';

import { Gender } from '@interfaces/common.interface';
import { CustomerType } from '@interfaces/customer.interface';

import { useCreateCustomerMutation } from '@api/customers.api';

import { selectPartecipants } from '@slices/checkout.slice';

import useGRecaptcha from '@hooks/useGRecaptcha';

import classes from './RegisterForm.module.css';

interface RegisterFormProps {
  onRegistrationCompleted: () => void;
}

export default function RegisterForm({
  onRegistrationCompleted,
}: RegisterFormProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const { getRecaptchaToken } = useGRecaptcha();

  // ==========================================================================
  // State
  // ==========================================================================
  const [isDomicileDifferent, setIsDomicileDifferent] = useState(false);

  const partecipants = useSelector(selectPartecipants);
  const holderPartecipant = useMemo(() => {
    const holder = partecipants[0];

    return holder;
  }, [partecipants]);

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: '', // required
    emailConfirm: '',
    password: '',
    passwordConfirm: '', // required
    type: (holderPartecipant ? 'private' : 'company') as CustomerType,
    firstName: holderPartecipant?.firstName || '',
    lastName: holderPartecipant?.lastName || '',
    companyName: '',
    pec: '',
    sdi: '',
    gender: (holderPartecipant?.gender || '') as Gender,
    address: holderPartecipant?.address || '',
    city: holderPartecipant?.city || '',
    zip: holderPartecipant?.zip || '',
    country: holderPartecipant?.country || '',
    province: holderPartecipant?.province || '',
    birthDate: holderPartecipant?.birthDate
      ? new Date(holderPartecipant.birthDate)
      : new Date(),
    birthCity: holderPartecipant?.birthCity || '',
    vatTaxNumber: holderPartecipant?.taxNumber || '',
    height: holderPartecipant?.height || 0,
    nationality: '',
    phone: '',
    privacy: false,
    newsLetter: false, // if true add user in MagNews service
  };

  const form = useForm({
    initialValues,
    validate: {
      emailConfirm: (value, values) =>
        value !== values.email ? 'Le email non corrispondono' : null,
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Le password non corrispondono' : null,
    },
  });

  // ==========================================================================
  // Api
  // ==========================================================================
  const [createCustomer, { isLoading }] = useCreateCustomerMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = async (values: typeof initialValues) => {
    try {
      const recaptchaToken = await getRecaptchaToken('SignUp');

      // TODO: change partecipant fields based on customer type

      await createCustomer({ body: values, recaptchaToken });

      onRegistrationCompleted();
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Divider
        label="Informazioni account"
        labelPosition="left"
        mt="xl"
        mb="sm"
        classNames={{ label: classes.dividerLabel }}
      />

      <Grid>
        <Grid.Col span={{ md: 3 }}>
          <TextInput
            label="Email"
            placeholder="email@example.com"
            required
            {...form.getInputProps('email')}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 3 }}>
          <TextInput
            label="Ripeti email"
            placeholder="email@example.com"
            required
            {...form.getInputProps('emailConfirm')}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 3 }}>
          <PasswordInput
            label="Password"
            placeholder="La tua password"
            required
            {...form.getInputProps('password')}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 3 }}>
          <PasswordInput
            label="Ripeti password"
            placeholder="La tua password"
            required
            {...form.getInputProps('passwordConfirm')}
          />
        </Grid.Col>
      </Grid>

      <Divider
        label="Informazioni base"
        labelPosition="left"
        mt="xl"
        mb="sm"
        classNames={{ label: classes.dividerLabel }}
      />

      <Checkbox
        label="Sono un agenzia"
        my="md"
        checked={form.getValues().type === 'company'}
        onChange={(e) =>
          form.setFieldValue(
            'type',
            e.currentTarget.checked ? 'company' : 'private',
          )
        }
      />

      <Grid>
        {form.getValues().type === 'private' && (
          <>
            <Grid.Col span={{ md: 4 }}>
              <TextInput
                label="Nome"
                placeholder="Il tuo nome"
                required
                {...form.getInputProps('firstName')}
              />
            </Grid.Col>
            <Grid.Col span={{ md: 4 }}>
              <TextInput
                label="Cognome"
                placeholder="Il tuo cognome"
                required
                {...form.getInputProps('lastName')}
              />
            </Grid.Col>
            <Grid.Col span={{ md: 4 }}>
              <TextInput
                label="Codice fiscale"
                placeholder="Il tuo codice fiscale"
                required
                {...form.getInputProps('vatTaxNumber')}
              />
            </Grid.Col>
            <Grid.Col span={{ md: 4 }}>
              <Select
                label="Sesso"
                placeholder="Sesso"
                required
                data={[
                  { label: 'Maschio', value: 'male' },
                  { label: 'Femmina', value: 'female' },
                ]}
                {...form.getInputProps('gender')}
              />
            </Grid.Col>
            <Grid.Col span={{ md: 4 }}>
              <NumberInput
                label="Altezza (cm)"
                placeholder="Altezza (cm)"
                required
                min={50}
                {...form.getInputProps('height')}
              />
            </Grid.Col>
            <Grid.Col span={{ md: 4 }}>
              <DateInput
                label="Data di nascita"
                placeholder="Data di nascita"
                required
                valueFormat="DD/MM/YYYY"
                {...form.getInputProps('birthDate')}
              />
            </Grid.Col>
            <Grid.Col span={{ md: 4 }}>
              <TextInput
                label="Città di nascita"
                placeholder="Città di nascita"
                required
                {...form.getInputProps('birthCity')}
              />
            </Grid.Col>
            <Grid.Col span={{ md: 4 }}>
              <TextInput
                label="Nazionalità"
                placeholder="Nazionalità"
                required
                {...form.getInputProps('nationality')}
              />
            </Grid.Col>
          </>
        )}

        {form.getValues().type === 'company' && (
          <>
            <Grid.Col span={{ md: 4 }}>
              <TextInput
                label="Nominativo / Ragione sociale"
                placeholder="Nominativo azienda"
                required
                {...form.getInputProps('companyName')}
              />
            </Grid.Col>
            <Grid.Col span={{ md: 4 }}>
              <TextInput
                label="P. IVA / CF"
                placeholder="P. IVA / CF"
                required
                {...form.getInputProps('vatTaxNumber')}
              />
            </Grid.Col>
            <Grid.Col span={{ md: 4 }}>
              <TextInput
                label="PEC"
                placeholder="PEC"
                {...form.getInputProps('pec')}
              />
            </Grid.Col>
            <Grid.Col span={{ md: 4 }}>
              <TextInput
                label="SDI"
                placeholder="SDI"
                {...form.getInputProps('sdi')}
              />
            </Grid.Col>
          </>
        )}

        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Telefono"
            placeholder="+39 123456789"
            required
            {...form.getInputProps('phone')}
          />
        </Grid.Col>
      </Grid>

      <Divider
        label="Residenza"
        labelPosition="left"
        mt="xl"
        mb="sm"
        classNames={{ label: classes.dividerLabel }}
      />

      <Grid>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Nazione"
            placeholder="Nazione residenza"
            required
            {...form.getInputProps('country')}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Provincia"
            placeholder="Provincia residenza"
            required
            {...form.getInputProps('province')}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Città"
            placeholder="Città residenza"
            required
            {...form.getInputProps('city')}
          />
        </Grid.Col>

        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="CAP"
            placeholder="CAP residenza"
            required
            {...form.getInputProps('zip')}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Indirizzo"
            placeholder="Indirizzo residenza"
            required
            {...form.getInputProps('address')}
          />
        </Grid.Col>
      </Grid>

      <Divider
        label="Domicilio"
        labelPosition="left"
        mt="xl"
        mb="sm"
        classNames={{ label: classes.dividerLabel }}
      />

      <Checkbox
        w="max-content"
        mb="lg"
        label="Domicilio diverso da residenza"
        checked={isDomicileDifferent}
        onChange={(e) => setIsDomicileDifferent(e.currentTarget.checked)}
      />

      {isDomicileDifferent && (
        <Grid>
          <Grid.Col span={{ md: 4 }}>
            <TextInput
              label="Nazione"
              placeholder="Nazione domicilio"
              required
              {...form.getInputProps('domicileCountry')}
            />
          </Grid.Col>
          <Grid.Col span={{ md: 4 }}>
            <TextInput
              label="Provincia"
              placeholder="Provincia domicilio"
              required
              {...form.getInputProps('domicileProvince')}
            />
          </Grid.Col>
          <Grid.Col span={{ md: 4 }}>
            <TextInput
              label="Città"
              placeholder="Città domicilio"
              required
              {...form.getInputProps('domicileCity')}
            />
          </Grid.Col>

          <Grid.Col span={{ md: 4 }}>
            <TextInput
              label="CAP"
              placeholder="CAP domicilio"
              required
              {...form.getInputProps('domicileZip')}
            />
          </Grid.Col>
          <Grid.Col span={{ md: 4 }}>
            <TextInput
              label="Indirizzo"
              placeholder="Indirizzo domicilio"
              required
              {...form.getInputProps('domicileAddress')}
            />
          </Grid.Col>
        </Grid>
      )}

      {/* TODO: privacy and newsletter */}

      {form.errors.general && (
        <Text c="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Button
        type="submit"
        mt="xl"
        style={{ display: 'block' }}
        loading={isLoading}
      >
        Registrati
      </Button>
    </form>
  );
}
