import api from './';

export const { useCreatePaymentMutation } = api.injectEndpoints({
  endpoints: (build) => ({
    createPayment: build.mutation<{ checkoutUrl: string }, void>({
      query: () => ({
        url: 'mollie/create-payment',
        method: 'POST',
      }),
    }),
  }),
});
