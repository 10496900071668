import { configureStore } from '@reduxjs/toolkit';

import apiComuni from '@api/locations.api';

import authSlice from '@slices/auth.slice';
import checkoutReducer from '@slices/checkout.slice';

import api from './api';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    checkout: checkoutReducer,
    [api.reducerPath]: api.reducer,
    [apiComuni.reducerPath]: apiComuni.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, apiComuni.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
