import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Group,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import { mapRoomTypeName } from '@utils/mappers';
import { priceToDecimal } from '@utils/price';

import { CheckoutPartecipant } from '@interfaces/checkout.interface';

import {
  selectCosts,
  selectEnsurances,
  selectExtras,
  selectHoliday,
  selectPartecipants,
  selectRooms,
} from '@slices/checkout.slice';

import useAuth from '@hooks/useAuth';

import LoginRegisterPage from '@components/LoginRegisterPage';

import CheckoutSidebar from './CheckoutSidebar';

interface SummaryProps {
  onConfirm: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Summary(_props: SummaryProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const { isAuthenticated } = useAuth();

  // ==========================================================================
  // State
  // ==========================================================================
  const partecipants = useSelector(selectPartecipants);
  // const rooms = useSelector(selectRooms);
  const costs = useSelector(selectCosts);

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================
  // const handleConfirm = () => {
  //   onConfirm();
  // };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Stack h="100%">
      {!isAuthenticated ? (
        <>
          <Alert color="red">
            Per continuare con la prenotazione devi prima registrarti
          </Alert>
          <LoginRegisterPage />
        </>
      ) : (
        <Grid flex={1} gutter="xl">
          <Grid.Col span={{ md: 8 }}>
            <Stack>
              <Title order={3}>Riepilogo e pagamento</Title>

              <Divider
                label="Partecipanti"
                labelPosition="left"
                fw="bold"
                styles={{
                  label: {
                    fontSize: '1.1rem',
                    color: 'var(--mantine-color-black)',
                  },
                }}
              />

              {partecipants.map((partecipant, index) => (
                <div key={partecipant.key}>
                  <Text key={partecipant.key} fw="bold" mb="xs">
                    {partecipant.firstName} {partecipant.lastName}
                    {index === 0 ? ' (intestatario prenotazione)' : ''}
                  </Text>
                  <PartecipantInfo partecipant={partecipant} />
                </div>
              ))}

              <Alert icon={<IconAlertTriangle />}>
                I dati mancanti dei partecipanti devono essere compilati entro
                24h dalla prenotazione. Riceverai le istruzioni per compilarli
                via email.
              </Alert>

              <Divider
                label="Codice sconto"
                labelPosition="left"
                fw="bold"
                styles={{
                  label: {
                    fontSize: '1.1rem',
                    color: 'var(--mantine-color-black)',
                  },
                }}
              />

              <Text size="sm">Hai un codice sconto? Inseriscilo qui</Text>
              <Group>
                <TextInput placeholder="Codice sconto" />
                <Button variant="outline">Applica</Button>
              </Group>
            </Stack>
          </Grid.Col>

          <Grid.Col span={{ md: 4 }}>
            <CheckoutSidebar costs={costs} submitLabel="CONFERMA E PAGA" />
          </Grid.Col>
        </Grid>
      )}
    </Stack>
  );
}

function PartecipantInfo({
  partecipant,
}: {
  partecipant: CheckoutPartecipant;
}) {
  const holiday = useSelector(selectHoliday)!;
  const partecipants = useSelector(selectPartecipants);
  const rooms = useSelector(selectRooms);
  const extras = useSelector(selectExtras);
  const ensurances = useSelector(selectEnsurances);
  const costs = useSelector(selectCosts);

  // TODO: spostare selector in padre. verificare performance con profiling

  const partecipantCosts = useMemo(
    () => ({
      holidayCost: costs.holidayCost / partecipants.length,
      extrasCosts: Object.entries(extras)
        .filter(([, info]) => info.partecipantsIds.includes(partecipant.key))
        .reduce(
          (acc, [extra]) =>
            acc + holiday.options.find((o) => o.id === extra)!.price,
          0,
        ),
      ensurancesCosts: ensurances.ensurance1.includes(partecipant.key) ? 50 : 0,
    }),
    [costs, partecipants],
  );

  return (
    <>
      <Grid gutter="xs" c="#444">
        <Grid.Col span={6}>
          <Text size="sm">
            <Text span fw="bold">
              CF:
            </Text>{' '}
            {partecipant.taxNumber || '-'}
          </Text>
          <Text size="sm">
            <Text span fw="bold">
              Sesso:
            </Text>{' '}
            {partecipant.gender === 'female' ? 'Femmina' : 'Maschio'}
          </Text>
          <Text size="sm">
            <Text span fw="bold">
              Data di nascita:
            </Text>{' '}
            {partecipant.birthDate
              ? new Date(partecipant.birthDate).toLocaleDateString()
              : '-'}
          </Text>
          <Text size="sm">
            <Text span fw="bold">
              Città di nascita:
            </Text>{' '}
            {partecipant.birthCity || '-'}
          </Text>
          <Text size="sm">
            <Text span fw="bold">
              Altezza:
            </Text>{' '}
            {partecipant.height ? `${partecipant.height} cm` : '-'}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text size="sm">
            <Text span fw="bold">
              Nazione:
            </Text>{' '}
            {partecipant.country || '-'}
          </Text>
          <Text size="sm">
            <Text span fw="bold">
              Provincia:
            </Text>{' '}
            {partecipant.province || '-'}
          </Text>
          <Text size="sm">
            <Text span fw="bold">
              Città:
            </Text>{' '}
            {partecipant.city || '-'}
          </Text>
          <Text size="sm">
            <Text span fw="bold">
              CAP:
            </Text>{' '}
            {partecipant.zip || '-'}
          </Text>
          <Text size="sm">
            <Text span fw="bold">
              Indirizzo:
            </Text>{' '}
            {partecipant.address || '-'}
          </Text>
        </Grid.Col>
        {partecipant.notes && (
          <Grid.Col span={12}>
            <Text span size="sm" fw="bold">
              Note / allergie / altre informazioni:
            </Text>{' '}
            <Text size="sm">{partecipant.notes}</Text>
          </Grid.Col>
        )}
      </Grid>

      <Box mt="md" c="#444">
        <Text size="sm" fw="bold" span>
          Camera:{' '}
        </Text>
        <Text size="sm" span>
          {mapRoomTypeName(
            rooms.find((r) => r.partecipantsIds.includes(partecipant.key))!
              .typeId!,
          )}
        </Text>
      </Box>

      <Table
        mt="md"
        w="min-content"
        cellPadding={0}
        cellSpacing={0}
        withRowBorders={false}
        styles={{ td: { padding: 0 } }}
      >
        <Table.Tbody>
          <Table.Tr>
            <Table.Td fs="italic" style={{ whiteSpace: 'nowrap' }}>
              Costo vacanza
            </Table.Td>
            <Table.Td style={{ whiteSpace: 'nowrap', paddingLeft: '1rem' }}>
              € {priceToDecimal(partecipantCosts.holidayCost)}
            </Table.Td>
          </Table.Tr>
          {partecipantCosts.extrasCosts > 0 && (
            <Table.Tr>
              <Table.Td fs="italic" style={{ whiteSpace: 'nowrap' }}>
                Extra
              </Table.Td>
              <Table.Td style={{ whiteSpace: 'nowrap', paddingLeft: '1rem' }}>
                € {priceToDecimal(partecipantCosts.extrasCosts)}
              </Table.Td>
            </Table.Tr>
          )}
          {partecipantCosts.ensurancesCosts > 0 && (
            <Table.Tr>
              <Table.Td fs="italic" style={{ whiteSpace: 'nowrap' }}>
                Assicurazioni
              </Table.Td>
              <Table.Td style={{ whiteSpace: 'nowrap', paddingLeft: '1rem' }}>
                € {priceToDecimal(partecipantCosts.ensurancesCosts)}
              </Table.Td>
            </Table.Tr>
          )}
          <Table.Tr>
            <Table.Td fs="italic" style={{ whiteSpace: 'nowrap' }}>
              TOTALE
            </Table.Td>
            <Table.Td style={{ whiteSpace: 'nowrap', paddingLeft: '1rem' }}>
              €{' '}
              {priceToDecimal(
                partecipantCosts.holidayCost +
                  partecipantCosts.extrasCosts +
                  partecipantCosts.ensurancesCosts,
              )}
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>

      <Divider my="md" />
    </>
  );
}
