import { useState } from 'react';

import { Alert, Anchor, Stack, Text, Title } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';

import LoginForm from './LoginForm';
import RegisterForm from './registerForm/RegisterForm';

export default function LoginRegisterPage() {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================
  const [isRegister, setIsRegister] = useState(true);
  const [showRegistrationSuccededNotice, setShowRegistrationSuccededNotice] =
    useState(false);

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Stack gap="xs">
      <Title order={3}>{isRegister ? 'Registrati' : 'Accedi'}</Title>
      <Text>
        {isRegister ? 'Hai già un account? ' : 'Non hai un account? '}
        <Anchor component="button" onClick={() => setIsRegister(!isRegister)}>
          {isRegister ? 'Accedi' : 'Registrati'}
        </Anchor>
      </Text>

      {showRegistrationSuccededNotice && (
        <Alert
          mt="md"
          color="green"
          icon={<IconCircleCheck />}
          variant="filled"
          radius="md"
          title="Registrazione effettuata con successo"
        >
          Controlla la tua casella email per confermare il tuo account
        </Alert>
      )}

      {isRegister ? (
        <RegisterForm
          onRegistrationCompleted={() => {
            setIsRegister(false);
            setShowRegistrationSuccededNotice(true);
          }}
        />
      ) : (
        <LoginForm />
      )}
    </Stack>
  );
}
