import { Button, Center } from '@mantine/core';

import { useCreatePaymentMutation } from '@api/mollie.api';

export default function MollieTest() {
  const [createPayment, { isLoading }] = useCreatePaymentMutation();

  const handleCreatePayment = async () => {
    try {
      const { checkoutUrl } = await createPayment().unwrap();

      location.href = checkoutUrl;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Center h="100dvh">
      <Button onClick={handleCreatePayment} loading={isLoading}>
        Pagamento di test
      </Button>
    </Center>
  );
}
