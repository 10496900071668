import { HolidayForCheckout } from '@interfaces/holidays.interface';

import api from './';

export interface GetHolidayForCheckoutRequest {
  holidayId: string;
  variationId: string;
}

export const { useGetHolidayForCheckoutQuery } = api.injectEndpoints({
  endpoints: (build) => ({
    getHolidayForCheckout: build.query<
      HolidayForCheckout,
      GetHolidayForCheckoutRequest
    >({
      query: ({ holidayId, variationId }) => ({
        url: `holidays/${holidayId}/variations/${variationId}/checkout`,
      }),
      providesTags: ['holidays'],
    }),
  }),
});
