import { Button, Grid, PasswordInput, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { handleSubmitError } from '@utils/forms';

import useAuth from '@hooks/useAuth';
import useGRecaptcha from '@hooks/useGRecaptcha';

interface LoginFormProps {
  onLoginCompleted?: () => void;
}

export default function LoginForm({ onLoginCompleted }: LoginFormProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const { getRecaptchaToken } = useGRecaptcha();
  const { signin, isLoading } = useAuth();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: '',
    password: '',
  };

  const form = useForm({
    initialValues,
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = async (values: typeof initialValues) => {
    try {
      const recaptchaToken = await getRecaptchaToken('SignIn');

      await signin({ body: values, recaptchaToken });

      // TODO: replace notification with something else

      // showNotification({
      //   title: 'Benvenuto',
      //   message:
      //     'La registrazione è andata a buon fine. Controlla la tua casella email per confermare il tuo account.',
      //   icon: <IconCheck />,
      //   color: 'teal',
      // });

      // navigate('/');

      onLoginCompleted?.();
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Grid mt="xl">
        <Grid.Col span={{ md: 3 }}>
          <TextInput
            label="Email"
            placeholder="email@example.com"
            required
            {...form.getInputProps('email')}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 3 }}>
          <PasswordInput
            label="Password"
            placeholder="La tua password"
            required
            {...form.getInputProps('password')}
          />
        </Grid.Col>
      </Grid>

      {form.errors.general && (
        <Text c="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Button
        type="submit"
        mt="xl"
        style={{ display: 'block' }}
        loading={isLoading}
      >
        Accedi
      </Button>
    </form>
  );
}
