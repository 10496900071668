import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Chip, Divider, Grid, Group, Stack, Text, Title } from '@mantine/core';
import { useForm } from '@mantine/form';

import {
  selectCosts,
  selectExtras,
  selectPartecipants,
  setExtras,
} from '@slices/checkout.slice';

import CheckoutSidebar from './CheckoutSidebar';

interface ExtraProps {
  onConfirm: () => void;
}

export default function Extras({ onConfirm }: ExtraProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();

  // ==========================================================================
  // State
  // ==========================================================================
  const costs = useSelector(selectCosts);
  const partecipants = useSelector(selectPartecipants);
  const extras = useSelector(selectExtras);

  // ==========================================================================
  // Form
  // ==========================================================================
  const extrasForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      extras: structuredClone(extras),
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = (values: typeof extrasForm.values) => {
    dispatch(setExtras(values.extras));

    onConfirm();
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const areAllExtrasAssigned = useMemo(() => {
    if (
      Object.keys(extras).length === 0 ||
      Object.values(extras).reduce((acc, e) => acc + e.quantity, 0) === 0
    ) {
      return true;
    }

    for (const extra of Object.values(extrasForm.getValues().extras)) {
      if (extra.quantity !== extra.partecipantsIds.length) {
        return false;
      }
    }

    return true;
  }, [extrasForm.getValues(), extras, partecipants]);

  return (
    <form onSubmit={extrasForm.onSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack h="100%">
        <Grid flex={1} gutter="xl">
          <Grid.Col span={{ md: 8 }}>
            <Title order={3} mb="lg">
              Associazione extra
            </Title>

            {Object.keys(extras).length === 0 ||
            Object.values(extras).reduce((acc, e) => acc + e.quantity, 0) ===
              0 ? (
              <Text>Nessun extra selezionato</Text>
            ) : (
              <Stack>
                {Object.entries(extrasForm.getValues().extras).map(
                  ([extra, info]) => (
                    <div key={extra}>
                      <Divider
                        label={extra}
                        labelPosition="left"
                        fw="bold"
                        styles={{
                          label: {
                            fontSize: '1.1rem',
                            color: 'var(--mantine-color-black)',
                          },
                        }}
                      />

                      <Text size="sm" mt="xs">
                        Seleziona {info.quantity} person
                        {info.quantity > 1 ? 'e' : 'a'}
                      </Text>

                      <Group mt="xs" gap="xs">
                        {partecipants.map((partecipant) => (
                          <Chip
                            key={extra + partecipant.key}
                            checked={info.partecipantsIds.includes(
                              partecipant.key,
                            )}
                            onChange={(checked) => {
                              if (checked) {
                                extrasForm.insertListItem(
                                  `extras.${extra}.partecipantsIds`,
                                  partecipant.key,
                                );
                              } else {
                                extrasForm.removeListItem(
                                  `extras.${extra}.partecipantsIds`,
                                  info.partecipantsIds.indexOf(partecipant.key),
                                );
                              }
                            }}
                            disabled={
                              !info.partecipantsIds.includes(partecipant.key) &&
                              info.partecipantsIds.length >= info.quantity //||
                              // !!extrasForm
                              //   .getValues()
                              //   .extras.find(
                              //     (r) =>
                              //       r.key !== extra.key &&
                              //       r.partecipantsIds.includes(partecipant.key),
                              //   )
                            }
                          >
                            {partecipant.firstName} {partecipant.lastName}
                          </Chip>
                        ))}
                      </Group>
                    </div>
                  ),
                )}
              </Stack>
            )}
          </Grid.Col>

          <Grid.Col span={{ md: 4 }}>
            <CheckoutSidebar
              costs={costs}
              submitDisabled={!areAllExtrasAssigned}
              submitDisabledMessage="Assegna tutti gli extra ai partecipanti per proseguire"
            />
          </Grid.Col>
        </Grid>
      </Stack>
    </form>
  );
}
