import {
  CreateCustomerRequest,
  Customer,
} from '@interfaces/customer.interface';

import api from './';

export const { useGetCurrentCustomerQuery, useCreateCustomerMutation } =
  api.injectEndpoints({
    endpoints: (build) => ({
      getCurrentCustomer: build.query<Customer, void>({
        query: () => ({
          url: 'customers/current',
        }),
        providesTags: ['customer'],
      }),
      createCustomer: build.mutation<Customer, CreateCustomerRequest>({
        query: ({ body, recaptchaToken }) => ({
          url: 'customers',
          method: 'POST',
          body,
          headers: {
            'x-grecaptcha': recaptchaToken,
          },
        }),
        invalidatesTags: ['customer'],
      }),
    }),
  });
