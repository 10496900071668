import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Chip,
  Divider,
  Grid,
  Group,
  Stack,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';

import {
  selectCosts,
  selectEnsurances,
  selectPartecipants,
  setCosts,
  setEnsurances,
} from '@slices/checkout.slice';

import CheckoutSidebar from './CheckoutSidebar';

interface EnsurancesProps {
  onConfirm: () => void;
}

export default function Ensurances({ onConfirm }: EnsurancesProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();

  // ==========================================================================
  // State
  // ==========================================================================
  const costs = useSelector(selectCosts);
  const partecipants = useSelector(selectPartecipants);
  const ensurances = useSelector(selectEnsurances);

  // ==========================================================================
  // Form
  // ==========================================================================
  const ensurancesForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      ensurances: structuredClone(ensurances),
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = (values: typeof ensurancesForm.values) => {
    dispatch(setEnsurances(values.ensurances));

    dispatch(
      setCosts({
        holidayCost: costs.holidayCost,
        roomsCost: costs.roomsCost,
        extrasCost: costs.extrasCost,
        ensurancesCost,
      }),
    );

    onConfirm();
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const ensurancesCost = useMemo(
    () => ensurancesForm.getValues().ensurances.ensurance1.length * 5000,
    [ensurancesForm.getValues()],
  );

  return (
    <form
      onSubmit={ensurancesForm.onSubmit(onSubmit)}
      style={{ height: '100%' }}
    >
      <Stack h="100%">
        <Grid flex={1} gutter="xl">
          <Grid.Col span={{ md: 8 }}>
            <Title order={3} mb="lg">
              Scelta assicurazioni
            </Title>

            <Stack>
              <div>
                <Divider
                  label="Assicurazione 1 (€ 50 / partecipante)"
                  labelPosition="left"
                  fw="bold"
                  styles={{
                    label: {
                      fontSize: '1.1rem',
                      color: 'var(--mantine-color-black)',
                    },
                  }}
                />

                <Text>Descrizione assicurazione.</Text>

                <Text size="sm" mt="xs">
                  Seleziona partecipanti
                </Text>

                <Group mt="xs" gap="xs">
                  {partecipants.map((partecipant) => (
                    <Tooltip
                      key={'ensurance1' + partecipant.key}
                      label={'Inserisci la data di nascita nel passaggio 1'}
                      styles={{
                        tooltip: {
                          display: partecipants.find(
                            (p) => p.key === partecipant.key,
                          )?.birthDate
                            ? 'none'
                            : undefined,
                        },
                      }}
                    >
                      <div>
                        <Chip
                          checked={ensurancesForm
                            .getValues()
                            .ensurances.ensurance1.includes(partecipant.key)}
                          onChange={(checked) => {
                            if (checked) {
                              ensurancesForm.insertListItem(
                                'ensurances.ensurance1',
                                partecipant.key,
                              );
                            } else {
                              ensurancesForm.removeListItem(
                                'ensurances.ensurance1',
                                ensurancesForm
                                  .getValues()
                                  .ensurances.ensurance1.indexOf(
                                    partecipant.key,
                                  ),
                              );
                            }
                          }}
                          disabled={
                            !partecipants.find((p) => p.key === partecipant.key)
                              ?.birthDate
                          }
                        >
                          {partecipant.firstName} {partecipant.lastName}
                        </Chip>
                      </div>
                    </Tooltip>
                  ))}
                </Group>
              </div>
            </Stack>
          </Grid.Col>

          <Grid.Col span={{ md: 4 }}>
            <CheckoutSidebar costs={{ ...costs, ensurancesCost }} />
          </Grid.Col>
        </Grid>
      </Stack>
    </form>
  );
}
