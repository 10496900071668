export const mapRoomTypeName = (typeId: string): string => {
  const [type, variant, shareable, sharedWith] = typeId.split('_');

  let name = '';

  switch (type) {
    case 'S':
      name = 'Singola';
      break;
    case 'D':
      name = 'Doppia';
      break;
    case 'T':
      name = 'Tripla';
      break;
    case 'Q':
      name = 'Quadrupla';
      break;
    case 'DUS':
      name = 'Doppia uso singolo';
      break;
    case 'M':
      name = 'Doppia matrimoniale';
      break;
    case 'CAST':
      name = 'Doppia (a castello)';
      break;
    case 'DIN':
      name = 'Dinette';
      break;
    case 'CUC':
      name = 'Cuccetta';
      break;
    case 'P':
      return 'Camerata';
  }

  switch (variant) {
    case 'S':
      name += ' superior';
      break;
    case 'D':
      name += ' deluxe';
      break;
    case 'SB':
      name += ' (bagno condiviso)';
      break;
    case 'PB':
      name += ' (bagno privato)';
      break;
  }

  if (shareable === 'S') {
    name += ' condivisa';
  }

  switch (sharedWith) {
    case 'M':
      name += ' (maschio)';
      break;
    case 'F':
      name += '  (femmina)';
      break;
  }

  return name;
};

export const mapRoomTypeInfo = (typeId: string): string | null => {
  const [, , shareable] = typeId.split('_');

  let info = null;

  if (shareable === 'S') {
    info = 'Camera in condivisione con partecipanti dello stesso sesso';
  }

  return info;
};
