import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Chip, Divider, Grid, Group, Stack, Text, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { mapRoomTypeName } from '@utils/mappers';

import {
  selectCosts,
  selectPartecipants,
  selectRooms,
  setRooms,
} from '@slices/checkout.slice';

import CheckoutSidebar from './CheckoutSidebar';

interface RoomsProps {
  onConfirm: () => void;
}

export default function Rooms({ onConfirm }: RoomsProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();

  // ==========================================================================
  // State
  // ==========================================================================
  const costs = useSelector(selectCosts);
  const rooms = useSelector(selectRooms);
  const partecipants = useSelector(selectPartecipants);

  // ==========================================================================
  // Form
  // ==========================================================================
  const roomsForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      rooms: structuredClone(rooms),
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = (values: typeof roomsForm.values) => {
    dispatch(setRooms(values.rooms));

    onConfirm();
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const areAllPartecipantsAllocated = useMemo(
    () =>
      roomsForm
        .getValues()
        .rooms.reduce((acc, r) => acc + r.partecipantsIds.length, 0) ===
      partecipants.length,
    [roomsForm.getValues(), partecipants],
  );

  return (
    <form onSubmit={roomsForm.onSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack h="100%">
        <Grid flex={1} gutter="xl">
          <Grid.Col span={{ md: 8 }}>
            <Title order={3} mb="lg">
              Associazione camere
            </Title>

            <Stack>
              {roomsForm.getValues().rooms.map((room, index) => (
                <div key={room.key}>
                  <Divider
                    label={`Camera ${mapRoomTypeName(room.typeId!)}`}
                    labelPosition="left"
                    fw="bold"
                    styles={{
                      label: {
                        fontSize: '1.1rem',
                        color: 'var(--mantine-color-black)',
                      },
                    }}
                  />

                  <Text size="sm" mt="xs">
                    Seleziona{' '}
                    {
                      room.room!.types.find((t) => t.typeId === room.typeId)!
                        .spotsPerBooking
                    }{' '}
                    person
                    {room.room!.types.find((t) => t.typeId === room.typeId)!
                      .spotsPerBooking > 1
                      ? 'e'
                      : 'a'}
                  </Text>

                  <Group mt="xs" gap="xs">
                    {partecipants.map((partecipant) => (
                      <Chip
                        key={room.key + partecipant.key}
                        checked={room.partecipantsIds.includes(partecipant.key)}
                        onChange={(checked) => {
                          if (checked) {
                            roomsForm.insertListItem(
                              `rooms.${index}.partecipantsIds`,
                              partecipant.key,
                            );
                          } else {
                            roomsForm.removeListItem(
                              `rooms.${index}.partecipantsIds`,
                              room.partecipantsIds.indexOf(partecipant.key),
                            );
                          }
                        }}
                        disabled={
                          (room.room?.sharedWith === 'male' &&
                            partecipant.gender === 'female') ||
                          (room.room?.sharedWith === 'female' &&
                            partecipant.gender === 'male') ||
                          (!room.partecipantsIds.includes(partecipant.key) &&
                            room.partecipantsIds.length >=
                              room.room!.types.find(
                                (t) => t.typeId === room.typeId,
                              )!.spotsPerBooking) ||
                          !!roomsForm
                            .getValues()
                            .rooms.find(
                              (r) =>
                                r.key !== room.key &&
                                r.partecipantsIds.includes(partecipant.key),
                            )
                        }
                      >
                        {partecipant.firstName} {partecipant.lastName}
                      </Chip>
                    ))}
                  </Group>
                </div>
              ))}
            </Stack>
          </Grid.Col>

          <Grid.Col span={{ md: 4 }}>
            <CheckoutSidebar
              costs={costs}
              submitDisabled={!areAllPartecipantsAllocated}
              submitDisabledMessage="Assegna tutti i partecipanti ad una camera per proseguire"
            />
          </Grid.Col>
        </Grid>
      </Stack>
    </form>
  );
}
