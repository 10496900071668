import parser from 'any-date-parser';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Alert,
  Anchor,
  Checkbox,
  Divider,
  Grid,
  Group,
  NumberInput,
  Select,
  Stack,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm, UseFormReturnType } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { closeModal, openModal } from '@mantine/modals';
import { Icon24Hours, IconUser } from '@tabler/icons-react';

import { CheckoutPartecipant } from '@interfaces/checkout.interface';

import { useGetCurrentCustomerQuery } from '@api/customers.api';

import {
  selectCosts,
  selectEnsurances,
  selectPartecipants,
  setPartecipants,
} from '@slices/checkout.slice';

import useAuth from '@hooks/useAuth';

import LoginForm from '@components/LoginForm';

import CheckoutSidebar from './CheckoutSidebar';

interface PartecipantsDetailsProps {
  onConfirm: () => void;
}

export default function Partecipants({ onConfirm }: PartecipantsDetailsProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();

  const { isAuthenticated } = useAuth();
  // TODO: get customer and compile first partecipant fields

  // ==========================================================================
  // State
  // ==========================================================================
  const costs = useSelector(selectCosts);
  const partecipants = useSelector(selectPartecipants);

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: customer } = useGetCurrentCustomerQuery(undefined, {
    skip: !isAuthenticated,
  });

  // ==========================================================================
  // Form
  // ==========================================================================
  const partecipantsForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      partecipants: structuredClone(
        partecipants.map((p) => ({
          ...p,
          // Set gender to undefined if coming from step 0 (aka name are not set)
          gender: p.firstName ? p.gender : undefined,
          birthDate: p.birthDate ? new Date(p.birthDate) : undefined,
        })),
      ),
    },
  });

  useEffect(() => {
    if (customer) {
      partecipantsForm.setFieldValue('partecipants.0', {
        key: randomId(),
        firstName: customer.user.firstName,
        lastName: customer.user.lastName,
        gender: customer.gender,
        birthDate: customer.birthDate
          ? new Date(customer.birthDate)
          : undefined,
        taxNumber: customer.taxNumber,
        birthCity: customer.birthCity,
        height: customer.height,
        country: customer.country,
        province: customer.province,
        city: customer.city,
        zip: customer.zip,
        address: customer.address,
      });
    }
  }, [customer]);

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onLoginClick = () => {
    openModal({
      modalId: 'login',
      title: 'Accedi',
      children: <LoginForm onLoginCompleted={() => closeModal('login')} />,
    });
  };

  const onSubmit = (values: typeof partecipantsForm.values) => {
    dispatch(
      setPartecipants(
        values.partecipants.map((p) => ({
          ...p,
          birthDate: p.birthDate?.toISOString(),
        })),
      ),
    );

    onConfirm();
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const areSelectedGendersValid = useMemo(() => {
    let availableFemale = partecipants.filter(
      (p) => p.gender === 'female',
    ).length;
    let availableMale = partecipants.filter((p) => p.gender === 'male').length;

    for (const partecipant of partecipantsForm.getValues().partecipants) {
      if (partecipant.gender === undefined) {
        return true;
      } else if (partecipant.gender === 'female') {
        availableFemale--;
        if (availableFemale < 0) {
          return false;
        }
      } else {
        availableMale--;
        if (availableMale < 0) {
          return false;
        }
      }
    }

    return availableFemale + availableMale === 0;
  }, [partecipants, partecipantsForm.getValues()]);

  return (
    <form
      onSubmit={partecipantsForm.onSubmit(onSubmit)}
      style={{ height: '100%' }}
    >
      <Stack h="100%">
        <Grid flex={1} gutter="xl">
          <Grid.Col span={{ md: 8 }}>
            <Title order={3} mb="lg">
              Dati partecipanti
            </Title>

            {!isAuthenticated && (
              <Alert mb="lg" icon={<IconUser />}>
                Hai già un account?{' '}
                <Anchor
                  size="sm"
                  component="button"
                  type="button"
                  onClick={onLoginClick}
                >
                  Accedi
                </Anchor>{' '}
                per inserire automaticamente i tuoi dati
              </Alert>
            )}

            <Stack gap="xl">
              {partecipantsForm
                .getValues()
                .partecipants.map((partecipant, index) => (
                  <PartecipantsInputs
                    key={partecipant.key}
                    index={index}
                    partecipantsForm={partecipantsForm}
                    partecipant={partecipant}
                  />
                ))}
            </Stack>
          </Grid.Col>

          <Grid.Col span={{ md: 4 }}>
            <CheckoutSidebar
              costs={costs}
              submitDisabled={!areSelectedGendersValid}
              submitDisabledMessage="Il sesso dei partecipanti non combacia con il numero
                    selezionato nel passaggio precedente"
            />
          </Grid.Col>
        </Grid>
      </Stack>
    </form>
  );
}

function PartecipantsInputs({
  index,
  partecipantsForm,
  partecipant,
}: {
  index: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  partecipantsForm: UseFormReturnType<any>;
  partecipant: Omit<CheckoutPartecipant, 'birthDate'>;
}) {
  const { i18n } = useTranslation();

  const ensurances = useSelector(selectEnsurances);

  return (
    <div>
      {index > 0 && (
        <Alert mb="lg" icon={<Icon24Hours />}>
          <Group justify="space-between">
            Non hai i dati di tutti i partecipanti? Inserisci solo nome, cognome
            e sesso e potrai aggiungere i dati mancanti successivamente, entro
            24h dalla prenotazione
          </Group>
        </Alert>
      )}

      <Divider
        label={`Partecipante ${index + 1}${index === 0 ? ' (intestatario prenotazione)' : ''}`}
        labelPosition="left"
        fw="bold"
        styles={{
          label: {
            fontSize: '1.1rem',
            color: 'var(--mantine-color-black)',
          },
        }}
      />

      <Grid mt="md">
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Nome"
            placeholder="Nome"
            required
            {...partecipantsForm.getInputProps(
              `partecipants.${index}.firstName`,
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Cognome"
            placeholder="Cognome"
            required
            {...partecipantsForm.getInputProps(
              `partecipants.${index}.lastName`,
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <Select
            label="Sesso"
            placeholder="Seleziona sesso"
            required
            {...partecipantsForm.getInputProps(`partecipants.${index}.gender`)}
            data={[
              { value: 'male', label: 'Maschio' },
              { value: 'female', label: 'Femmina' },
            ]}
          />
        </Grid.Col>

        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Codice fiscale"
            placeholder="Codice fiscale"
            required={index === 0}
            {...partecipantsForm.getInputProps(
              `partecipants.${index}.taxNumber`,
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <DateInput
            label="Data di nascita"
            placeholder="Data di nascita"
            required={index === 0}
            {...partecipantsForm.getInputProps(
              `partecipants.${index}.birthDate`,
            )}
            dateParser={(value) => parser.fromString(value, i18n.language)}
            valueFormat="DD/MM/YYYY"
            // TODO: min date
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Città di nascita"
            placeholder="Città di nascita"
            required={index === 0}
            {...partecipantsForm.getInputProps(
              `partecipants.${index}.birthCity`,
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <NumberInput
            label="Altezza (cm)"
            placeholder="Altezza (cm)"
            min={50}
            required={index === 0}
            {...partecipantsForm.getInputProps(`partecipants.${index}.height`)}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Nazione"
            placeholder="Nazione"
            required={index === 0}
            {...partecipantsForm.getInputProps(`partecipants.${index}.country`)}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Provincia"
            placeholder="Provincia"
            required={index === 0}
            {...partecipantsForm.getInputProps(
              `partecipants.${index}.province`,
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Città"
            placeholder="Città"
            required={index === 0}
            {...partecipantsForm.getInputProps(`partecipants.${index}.city`)}
          />
        </Grid.Col>

        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="CAP"
            placeholder="CAP"
            required={index === 0}
            {...partecipantsForm.getInputProps(`partecipants.${index}.zip`)}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Indirizzo"
            placeholder="Indirizzo"
            required={index === 0}
            {...partecipantsForm.getInputProps(`partecipants.${index}.address`)}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <Textarea
            label="Note / allergie / altre informazioni"
            placeholder="Note / allergie / altre informazioni"
            {...partecipantsForm.getInputProps(`partecipants.${index}.notes`)}
          />
        </Grid.Col>

        {ensurances.ensurance1.includes(partecipant.key) && (
          <Grid.Col span={12}>
            <Checkbox
              label="Dichiara di essere cittadino italiano domiciliato in italia e di voler stipulare la polizza selezionata nello step precedente"
              required
              checked={
                partecipantsForm.getValues()[`partecipants.${index}.isItalian`]
              }
              onChange={(e) =>
                partecipantsForm.setFieldValue(
                  `partecipants.${index}.isItalian`,
                  e.currentTarget.checked,
                )
              }
            />
          </Grid.Col>
        )}
      </Grid>
    </div>
  );
}
