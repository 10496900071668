import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ActionIcon,
  Alert,
  Button,
  Divider,
  Grid,
  Group,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import {
  IconAlertCircle,
  IconInfoCircle,
  IconMinus,
  IconPlus,
} from '@tabler/icons-react';
import { mapRoomTypeInfo, mapRoomTypeName } from '@utils/mappers';
import { priceToDecimal } from '@utils/price';

import {
  CheckoutExtras,
  CheckoutPartecipant,
  CheckoutRoom,
} from '@interfaces/checkout.interface';
import {
  HolidayVariationRoom,
  HolidayVariationRoomTypes,
} from '@interfaces/holidays.interface';

import {
  selectHoliday,
  setCosts,
  setExtras,
  setPartecipants,
  setRooms,
} from '@slices/checkout.slice';

import CheckoutSidebar from '../CheckoutSidebar';
import classes from './Quotation.module.css';

interface QuotationProps {
  onConfirm: (selectedRooms: CheckoutRoom[]) => void;
}

export default function Quotation({ onConfirm }: QuotationProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();

  const holiday = useSelector(selectHoliday)!;

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================
  const quotationForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      partecipants: {
        maleCount: 0,
        femaleCount: 0,
      },
      rooms: [] as CheckoutRoom[],
      extras: {} as CheckoutExtras,
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = (values: typeof quotationForm.values) => {
    const partecipants: CheckoutPartecipant[] = [];

    for (let i = 0; i < values.partecipants.femaleCount; i++) {
      partecipants.push({ key: randomId(), gender: 'female' });
    }

    for (let i = 0; i < values.partecipants.maleCount; i++) {
      partecipants.push({ key: randomId(), gender: 'male' });
    }

    dispatch(setPartecipants(partecipants));
    dispatch(setRooms(values.rooms));
    dispatch(setExtras(values.extras));
    dispatch(
      setCosts({
        holidayCost,
        roomsCost,
        extrasCost,
        ensurancesCost: 0,
      }),
    );

    onConfirm(values.rooms);
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const initialAvailableRoomTypes = useMemo(() => {
    const roomTypes: Partial<Record<string, HolidayVariationRoomTypes>> = {};

    for (const room of holiday.rooms) {
      for (const roomType of room.types) {
        let type = roomType.typeId;

        if (roomType.typeId.split('_')[2] === 'S') {
          if (room.sharedWith === 'male') {
            type += '_M';
          } else if (room.sharedWith === 'female') {
            type += '_F';
          }
        }

        if (!roomTypes[type]) {
          roomTypes[type] = roomType;
        }
      }
    }

    return roomTypes;
  }, [holiday]);

  // Group rooms by type
  const mappedAvailableRooms = useMemo(() => {
    const mappedRooms: Partial<Record<string, HolidayVariationRoom[]>> = {};

    for (const room of holiday.rooms) {
      if (quotationForm.getValues().rooms.find((r) => r.room?.id === room.id)) {
        // Skip room if selected
        continue;
      }

      for (const roomType of room.types) {
        let type = roomType.typeId;

        if (roomType.typeId.split('_')[2] === 'S') {
          if (room.sharedWith === 'male') {
            type += '_M';
          } else if (room.sharedWith === 'female') {
            type += '_F';
          }
        }

        if (!mappedRooms[type]) {
          mappedRooms[type] = [];
        }

        mappedRooms[type]!.push(room);

        mappedRooms[type]!.sort((a, b) =>
          a.types.length > b.types.length ? 1 : -1,
        );
      }
    }

    return mappedRooms;
  }, [holiday, quotationForm.getValues()]);

  const partecipantsCount = useMemo(
    () =>
      quotationForm.getValues().partecipants.femaleCount +
      quotationForm.getValues().partecipants.maleCount,
    [quotationForm.getValues().partecipants],
  );

  const holidayCost = useMemo(
    () => holiday.price * partecipantsCount,
    [holiday.price, partecipantsCount],
  );

  const roomsCost = useMemo(
    () =>
      quotationForm
        .getValues()
        .rooms.reduce(
          (acc, r) =>
            acc + r.room!.types.find((t) => t.typeId === r.typeId)!.price,
          0,
        ),
    [quotationForm.getValues()],
  );

  const extrasCost = useMemo(
    () =>
      Object.entries(quotationForm.getValues().extras).reduce(
        (acc, [extra, { quantity }]) =>
          acc + holiday.options.find((o) => o.id === extra)!.price * quantity,
        0,
      ),
    [holiday.options, quotationForm.getValues()],
  );

  const areRoomsValids = useMemo(() => {
    let remainingFemalePartecipants =
      quotationForm.getValues().partecipants.femaleCount;
    let remainingMalePartecipants =
      quotationForm.getValues().partecipants.maleCount;

    let femaleOnlySpots = 0;
    let maleOnlySpots = 0;
    let unisexSpots = 0;

    for (const room of quotationForm.getValues().rooms) {
      if (room.room?.sharedWith === 'female') {
        femaleOnlySpots++;
      } else if (room.room?.sharedWith === 'male') {
        maleOnlySpots++;
      } else {
        unisexSpots += room.room!.types.find(
          (t) => t.typeId === room.typeId,
        )!.spotsPerBooking;
      }
    }

    for (let i = 0; i < femaleOnlySpots; i++) {
      remainingFemalePartecipants--;
      if (remainingFemalePartecipants < 0) {
        return false;
      }
    }

    for (let i = 0; i < maleOnlySpots; i++) {
      remainingMalePartecipants--;
      if (remainingMalePartecipants < 0) {
        return false;
      }
    }

    for (let i = 0; i < unisexSpots; i++) {
      if (remainingMalePartecipants === 0) {
        remainingFemalePartecipants--;
        if (remainingFemalePartecipants < 0) {
          return false;
        }
      } else {
        remainingMalePartecipants--;
      }
    }

    return remainingFemalePartecipants + remainingMalePartecipants === 0;
  }, [quotationForm.getValues()]);

  const areExtrasValids = useMemo(() => {
    for (const [, { quantity }] of Object.entries(
      quotationForm.getValues().extras,
    )) {
      if (quantity > partecipantsCount) {
        return false;
      }
    }

    return true;
  }, [quotationForm.getValues(), partecipantsCount]);

  return (
    <form onSubmit={quotationForm.onSubmit(onSubmit)}>
      <Stack h="100%">
        <Grid flex={1} gutter="xl">
          <Grid.Col span={{ md: 8 }}>
            <Divider
              label="Seleziona il numero di partecipanti"
              labelPosition="left"
              classNames={{ label: classes.dividerLabel }}
            />

            <Alert fw="bold" mt="md">
              <Group justify="space-between">
                <Group gap="xs">
                  <ThemeIcon variant="transparent">
                    <IconAlertCircle />
                  </ThemeIcon>
                  Se viaggi con minori di 10 anni o con animali contattaci per
                  maggiori informazioni
                </Group>
                <Button variant="outline">Contattaci</Button>
              </Group>
            </Alert>

            <Grid gutter="xl" mt="xl">
              <Grid.Col span={{ md: 6 }}>
                <Group wrap="nowrap">
                  <Text fw="bold" size="lg" mr="lg">
                    Donne
                  </Text>
                  <ActionIcon
                    onClick={() => {
                      quotationForm.setFieldValue(
                        'partecipants.femaleCount',
                        quotationForm.getValues().partecipants.femaleCount - 1,
                      );
                    }}
                    disabled={
                      quotationForm.getValues().partecipants.femaleCount === 0
                    }
                  >
                    <IconMinus />
                  </ActionIcon>
                  <Text>
                    {quotationForm.getValues().partecipants.femaleCount}
                  </Text>
                  <ActionIcon
                    onClick={() => {
                      quotationForm.setFieldValue(
                        'partecipants.femaleCount',
                        quotationForm.getValues().partecipants.femaleCount + 1,
                      );
                    }}
                  >
                    <IconPlus />
                  </ActionIcon>
                </Group>
              </Grid.Col>
              <Grid.Col span={{ md: 6 }}>
                <Group wrap="nowrap">
                  <Text fw="bold" size="lg" mr="lg">
                    Uomini
                  </Text>
                  <ActionIcon
                    onClick={() => {
                      quotationForm.setFieldValue(
                        'partecipants.maleCount',
                        quotationForm.getValues().partecipants.maleCount - 1,
                      );
                    }}
                    disabled={
                      quotationForm.getValues().partecipants.maleCount === 0
                    }
                  >
                    <IconMinus />
                  </ActionIcon>
                  <Text>
                    {quotationForm.getValues().partecipants.maleCount}
                  </Text>
                  <ActionIcon
                    onClick={() => {
                      quotationForm.setFieldValue(
                        'partecipants.maleCount',
                        quotationForm.getValues().partecipants.maleCount + 1,
                      );
                    }}
                  >
                    <IconPlus />
                  </ActionIcon>
                </Group>
              </Grid.Col>
            </Grid>

            <Divider
              label="Seleziona le camere"
              labelPosition="left"
              mt="xl"
              classNames={{ label: classes.dividerLabel }}
            />

            <Grid gutter="xl" mt="xl">
              {Object.entries(initialAvailableRoomTypes).map(
                ([typeId, info]) => (
                  <Grid.Col key={typeId} span={{ md: 6 }}>
                    <Group wrap="nowrap" justify="space-between">
                      <Group gap="xs">
                        <Text fw="bold" size="lg">
                          {mapRoomTypeName(typeId)}
                          {info!.price !== 0 &&
                            ` (€ ${priceToDecimal(info!.price)})`}
                        </Text>
                        {mapRoomTypeInfo(typeId) && (
                          <Tooltip
                            label={mapRoomTypeInfo(typeId)}
                            multiline
                            maw="15rem"
                            ta="center"
                          >
                            <IconInfoCircle />
                          </Tooltip>
                        )}
                      </Group>
                      <Group>
                        <ActionIcon
                          onClick={() =>
                            quotationForm.removeListItem(
                              'rooms',
                              quotationForm
                                .getValues()
                                .rooms.findLastIndex(
                                  (r) => r.typeId === typeId,
                                ),
                            )
                          }
                          disabled={
                            !quotationForm
                              .getValues()
                              .rooms.find((r) => r.typeId === typeId)
                          }
                        >
                          <IconMinus />
                        </ActionIcon>
                        <Text>
                          {
                            quotationForm
                              .getValues()
                              .rooms.filter((r) => r.typeId === typeId).length
                          }
                        </Text>
                        <ActionIcon
                          onClick={() => {
                            quotationForm.insertListItem('rooms', {
                              key: randomId(),
                              room: mappedAvailableRooms[typeId]![0],
                              typeId,
                              partecipantsIds: [],
                            });
                          }}
                          disabled={!mappedAvailableRooms[typeId]}
                        >
                          <IconPlus />
                        </ActionIcon>
                      </Group>
                    </Group>
                  </Grid.Col>
                ),
              )}
            </Grid>

            <Divider
              label="Seleziona gli extra"
              labelPosition="left"
              mt="xl"
              classNames={{ label: classes.dividerLabel }}
            />

            <Grid gutter="xl" mt="xl">
              {holiday.options.map((option) => (
                <Grid.Col key={option.id} span={12}>
                  <Group wrap="nowrap" justify="space-between">
                    <Text fw="bold" size="lg">
                      {option.name} (€{' '}
                      {priceToDecimal(option.price) +
                        (option.type === 'perPartecipant'
                          ? '/partecipante'
                          : '')}
                      )
                    </Text>
                    <Group>
                      <ActionIcon
                        onClick={() =>
                          quotationForm.setFieldValue(`extras.${option.id}`, {
                            quantity:
                              (quotationForm.getValues().extras[option.id]
                                ?.quantity || 1) - 1,
                            partecipantsIds: [],
                          })
                        }
                        disabled={
                          (quotationForm.getValues().extras[option.id]
                            ?.quantity || 0) === 0
                        }
                      >
                        <IconMinus />
                      </ActionIcon>
                      <Text>
                        {quotationForm.getValues().extras[option.id]
                          ?.quantity || 0}
                      </Text>
                      <ActionIcon
                        onClick={() =>
                          quotationForm.setFieldValue(`extras.${option.id}`, {
                            quantity:
                              (quotationForm.getValues().extras[option.id]
                                ?.quantity || 0) + 1,
                            partecipantsIds: [],
                          })
                        }
                        disabled={
                          (quotationForm.getValues().extras[option.id]
                            ?.quantity || 0) === option.quantity ||
                          (quotationForm.getValues().extras[option.id]
                            ?.quantity || 0) >= partecipantsCount
                        }
                      >
                        <IconPlus />
                      </ActionIcon>
                    </Group>
                  </Group>
                </Grid.Col>
              ))}
            </Grid>
          </Grid.Col>

          <Grid.Col span={{ md: 4 }}>
            <CheckoutSidebar
              costs={{
                holidayCost,
                roomsCost,
                extrasCost,
                ensurancesCost: 0,
              }}
              showRemainingTime={false}
              submitDisabled={
                partecipantsCount === 0 || !areRoomsValids || !areExtrasValids
              }
              submitDisabledMessage={
                partecipantsCount === 0
                  ? 'Aggiungi almeno un partecipante per proseguire'
                  : !areRoomsValids
                    ? 'Le stanze selezionate non sono compatibili con i partecipanti selezionati'
                    : !areExtrasValids
                      ? 'Gli extra selezionate non sono compatibili con il numero di partecipanti selezionati'
                      : undefined
              }
            />
          </Grid.Col>
        </Grid>
      </Stack>
    </form>
  );
}
