import { useSelector } from 'react-redux';

import { Alert, Button, Group, Paper, Stack, Table, Text } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { secondsToFormattedTime } from '@utils/date';
import { priceToDecimal } from '@utils/price';

import { CheckoutCosts } from '@interfaces/checkout.interface';

import {
  selectHoliday,
  selectSessionRemainingTime,
} from '@slices/checkout.slice';

interface CheckoutSidebarProps {
  costs: CheckoutCosts;
  showRemainingTime?: boolean;
  submitLabel?: string;
  submitDisabled?: boolean;
  submitDisabledMessage?: string;
}

export default function CheckoutSidebar({
  costs,
  showRemainingTime = true,
  submitLabel = 'PROSEGUI',
  submitDisabled = false,
  submitDisabledMessage = '',
}: CheckoutSidebarProps) {
  const holiday = useSelector(selectHoliday)!;
  const sessionRemainingTime = useSelector(selectSessionRemainingTime);

  return (
    <Paper bg="brand" c="white" p="lg" pos="sticky" top="2rem">
      <Stack gap={0}>
        <Text fw="bold" size="xl" ta="center">
          {holiday.holiday.name}
        </Text>
        <Text c="#ddd" ta="center">
          {new Intl.DateTimeFormat('it', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          }).format(new Date(holiday.startDate))}{' '}
          -{' '}
          {new Intl.DateTimeFormat('it', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          }).format(new Date(holiday.endDate))}
        </Text>

        <Table
          mt="lg"
          mb="xl"
          withRowBorders={false}
          cellPadding={0}
          cellSpacing={0}
        >
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>
                <Text fs="italic" style={{ whiteSpace: 'nowrap' }}>
                  Costo vacanza
                </Text>
              </Table.Td>
              <Table.Td align="right">
                <Text fw="bold" style={{ whiteSpace: 'nowrap' }}>
                  € {priceToDecimal(costs.holidayCost)}
                </Text>
              </Table.Td>
            </Table.Tr>
            {costs.roomsCost !== 0 && (
              <Table.Tr>
                <Table.Td>
                  <Text fs="italic" style={{ whiteSpace: 'nowrap' }}>
                    Costo camere
                  </Text>
                </Table.Td>
                <Table.Td align="right">
                  <Text fw="bold" style={{ whiteSpace: 'nowrap' }}>
                    € {priceToDecimal(costs.roomsCost)}
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
            {costs.extrasCost !== 0 && (
              <Table.Tr>
                <Table.Td>
                  <Text fs="italic" style={{ whiteSpace: 'nowrap' }}>
                    Costo extra
                  </Text>
                </Table.Td>
                <Table.Td align="right">
                  <Text fw="bold" style={{ whiteSpace: 'nowrap' }}>
                    € {priceToDecimal(costs.extrasCost)}
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
            {costs.ensurancesCost !== 0 && (
              <Table.Tr>
                <Table.Td>
                  <Text fs="italic" style={{ whiteSpace: 'nowrap' }}>
                    Costo assicurazioni
                  </Text>
                </Table.Td>
                <Table.Td align="right">
                  <Text fw="bold" style={{ whiteSpace: 'nowrap' }}>
                    € {priceToDecimal(costs.ensurancesCost)}
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
            <Table.Tr style={{ borderTop: '1px solid #E9ECEF' }}>
              <Table.Td>
                <Text fs="italic">TOTALE</Text>
              </Table.Td>
              <Table.Td align="right">
                <Text fw="bold" size="xl">
                  €{' '}
                  {priceToDecimal(
                    costs.holidayCost +
                      costs.roomsCost +
                      costs.extrasCost +
                      costs.ensurancesCost,
                  )}
                </Text>
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>

        {showRemainingTime && (
          <Alert variant="white" mb="lg" ta="center">
            <Group justify="center" gap="xs">
              {/* <ThemeIcon variant="transparent">
                      <IconHourglassHigh />
                    </ThemeIcon> */}
              <Text c="#e51c9d" fw="800" size="xl">
                {secondsToFormattedTime(sessionRemainingTime)}
              </Text>
            </Group>
            <Text size="sm">
              Tempo rimanente per completare la prenotazione e confermare le
              stanze scelte
            </Text>
          </Alert>
        )}

        {submitDisabled && (
          <Alert
            color="red"
            variant="filled"
            mb="sm"
            icon={<IconAlertCircle />}
          >
            {submitDisabledMessage}
          </Alert>
        )}

        <Button
          variant="white"
          size="lg"
          w="100%"
          type="submit"
          disabled={submitDisabled}
        >
          {submitLabel}
        </Button>
      </Stack>
    </Paper>
  );
}
