import { createBrowserRouter } from 'react-router-dom';

import Checkout from './Checkout';
import MollieRedirectTest from './MollieRedirectTest';
import MollieTest from './MollieTest';
import NotFound from './NotFound';

const router = createBrowserRouter([
  {
    path: '/checkout',
    element: <Checkout />,
  },
  {
    path: '/mollie',
    element: <MollieTest />,
  },
  {
    path: '/mollie-redirect',
    element: <MollieRedirectTest />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

export default router;
